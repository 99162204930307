/* tslint:disable */
/* eslint-disable */
/**
 * Immutable X API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0
 * Contact: support@immutable.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIError } from '../models';
// @ts-ignore
import { Asset } from '../models';
// @ts-ignore
import { ListAssetsResponse } from '../models';
/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get details of an asset
         * @summary Get details of an asset
         * @param {string} tokenAddress Address of the ERC721 contract
         * @param {string} tokenId Either ERC721 token ID or internal IMX ID
         * @param {boolean} [includeFees] Set flag to include fees associated with the asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (tokenAddress: string, tokenId: string, includeFees?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('getAsset', 'tokenAddress', tokenAddress)
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('getAsset', 'tokenId', tokenId)
            const localVarPath = `/v1/assets/{token_address}/{token_id}`
                .replace(`{${"token_address"}}`, encodeURIComponent(String(tokenAddress)))
                .replace(`{${"token_id"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeFees !== undefined) {
                localVarQueryParameter['include_fees'] = includeFees;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of assets
         * @summary Get a list of assets
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'updated_at' | 'name'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who owns these assets
         * @param {'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned'} [status] Status of these assets
         * @param {string} [name] Name of the asset to search
         * @param {string} [metadata] URL JSON-encoded metadata filters for these assets. Javascript example: encodeURI(JSON.stringify({\&#39;proto\&#39;:[\&#39;1147\&#39;],\&#39;quality\&#39;:[\&#39;Meteorite\&#39;]}))
         * @param {boolean} [sellOrders] Set flag to true to fetch an array of sell order details with accepted status associated with the asset
         * @param {boolean} [buyOrders] Set flag to true to fetch an array of buy order details  with accepted status associated with the asset
         * @param {boolean} [includeFees] Set flag to include fees associated with the asset
         * @param {string} [collection] Collection contract address
         * @param {string} [updatedMinTimestamp] Minimum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMaxTimestamp] Maximum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets: async (pageSize?: number, cursor?: string, orderBy?: 'updated_at' | 'name', direction?: string, user?: string, status?: 'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned', name?: string, metadata?: string, sellOrders?: boolean, buyOrders?: boolean, includeFees?: boolean, collection?: string, updatedMinTimestamp?: string, updatedMaxTimestamp?: string, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (metadata !== undefined) {
                localVarQueryParameter['metadata'] = metadata;
            }

            if (sellOrders !== undefined) {
                localVarQueryParameter['sell_orders'] = sellOrders;
            }

            if (buyOrders !== undefined) {
                localVarQueryParameter['buy_orders'] = buyOrders;
            }

            if (includeFees !== undefined) {
                localVarQueryParameter['include_fees'] = includeFees;
            }

            if (collection !== undefined) {
                localVarQueryParameter['collection'] = collection;
            }

            if (updatedMinTimestamp !== undefined) {
                localVarQueryParameter['updated_min_timestamp'] = updatedMinTimestamp;
            }

            if (updatedMaxTimestamp !== undefined) {
                localVarQueryParameter['updated_max_timestamp'] = updatedMaxTimestamp;
            }

            if (auxiliaryFeePercentages !== undefined) {
                localVarQueryParameter['auxiliary_fee_percentages'] = auxiliaryFeePercentages;
            }

            if (auxiliaryFeeRecipients !== undefined) {
                localVarQueryParameter['auxiliary_fee_recipients'] = auxiliaryFeeRecipients;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get details of an asset
         * @summary Get details of an asset
         * @param {string} tokenAddress Address of the ERC721 contract
         * @param {string} tokenId Either ERC721 token ID or internal IMX ID
         * @param {boolean} [includeFees] Set flag to include fees associated with the asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(tokenAddress: string, tokenId: string, includeFees?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(tokenAddress, tokenId, includeFees, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of assets
         * @summary Get a list of assets
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'updated_at' | 'name'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who owns these assets
         * @param {'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned'} [status] Status of these assets
         * @param {string} [name] Name of the asset to search
         * @param {string} [metadata] URL JSON-encoded metadata filters for these assets. Javascript example: encodeURI(JSON.stringify({\&#39;proto\&#39;:[\&#39;1147\&#39;],\&#39;quality\&#39;:[\&#39;Meteorite\&#39;]}))
         * @param {boolean} [sellOrders] Set flag to true to fetch an array of sell order details with accepted status associated with the asset
         * @param {boolean} [buyOrders] Set flag to true to fetch an array of buy order details  with accepted status associated with the asset
         * @param {boolean} [includeFees] Set flag to include fees associated with the asset
         * @param {string} [collection] Collection contract address
         * @param {string} [updatedMinTimestamp] Minimum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMaxTimestamp] Maximum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssets(pageSize?: number, cursor?: string, orderBy?: 'updated_at' | 'name', direction?: string, user?: string, status?: 'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned', name?: string, metadata?: string, sellOrders?: boolean, buyOrders?: boolean, includeFees?: boolean, collection?: string, updatedMinTimestamp?: string, updatedMaxTimestamp?: string, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAssetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssets(pageSize, cursor, orderBy, direction, user, status, name, metadata, sellOrders, buyOrders, includeFees, collection, updatedMinTimestamp, updatedMaxTimestamp, auxiliaryFeePercentages, auxiliaryFeeRecipients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * Get details of an asset
         * @summary Get details of an asset
         * @param {string} tokenAddress Address of the ERC721 contract
         * @param {string} tokenId Either ERC721 token ID or internal IMX ID
         * @param {boolean} [includeFees] Set flag to include fees associated with the asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(tokenAddress: string, tokenId: string, includeFees?: boolean, options?: any): AxiosPromise<Asset> {
            return localVarFp.getAsset(tokenAddress, tokenId, includeFees, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of assets
         * @summary Get a list of assets
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'updated_at' | 'name'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who owns these assets
         * @param {'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned'} [status] Status of these assets
         * @param {string} [name] Name of the asset to search
         * @param {string} [metadata] URL JSON-encoded metadata filters for these assets. Javascript example: encodeURI(JSON.stringify({\&#39;proto\&#39;:[\&#39;1147\&#39;],\&#39;quality\&#39;:[\&#39;Meteorite\&#39;]}))
         * @param {boolean} [sellOrders] Set flag to true to fetch an array of sell order details with accepted status associated with the asset
         * @param {boolean} [buyOrders] Set flag to true to fetch an array of buy order details  with accepted status associated with the asset
         * @param {boolean} [includeFees] Set flag to include fees associated with the asset
         * @param {string} [collection] Collection contract address
         * @param {string} [updatedMinTimestamp] Minimum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMaxTimestamp] Maximum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets(pageSize?: number, cursor?: string, orderBy?: 'updated_at' | 'name', direction?: string, user?: string, status?: 'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned', name?: string, metadata?: string, sellOrders?: boolean, buyOrders?: boolean, includeFees?: boolean, collection?: string, updatedMinTimestamp?: string, updatedMaxTimestamp?: string, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, options?: any): AxiosPromise<ListAssetsResponse> {
            return localVarFp.listAssets(pageSize, cursor, orderBy, direction, user, status, name, metadata, sellOrders, buyOrders, includeFees, collection, updatedMinTimestamp, updatedMaxTimestamp, auxiliaryFeePercentages, auxiliaryFeeRecipients, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAsset operation in AssetsApi.
 * @export
 * @interface AssetsApiGetAssetRequest
 */
export interface AssetsApiGetAssetRequest {
    /**
     * Address of the ERC721 contract
     * @type {string}
     * @memberof AssetsApiGetAsset
     */
    readonly tokenAddress: string

    /**
     * Either ERC721 token ID or internal IMX ID
     * @type {string}
     * @memberof AssetsApiGetAsset
     */
    readonly tokenId: string

    /**
     * Set flag to include fees associated with the asset
     * @type {boolean}
     * @memberof AssetsApiGetAsset
     */
    readonly includeFees?: boolean
}

/**
 * Request parameters for listAssets operation in AssetsApi.
 * @export
 * @interface AssetsApiListAssetsRequest
 */
export interface AssetsApiListAssetsRequest {
    /**
     * Page size of the result
     * @type {number}
     * @memberof AssetsApiListAssets
     */
    readonly pageSize?: number

    /**
     * Cursor
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly cursor?: string

    /**
     * Property to sort by
     * @type {'updated_at' | 'name'}
     * @memberof AssetsApiListAssets
     */
    readonly orderBy?: 'updated_at' | 'name'

    /**
     * Direction to sort (asc/desc)
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly direction?: string

    /**
     * Ethereum address of the user who owns these assets
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly user?: string

    /**
     * Status of these assets
     * @type {'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned'}
     * @memberof AssetsApiListAssets
     */
    readonly status?: 'eth' | 'imx' | 'preparing_withdrawal' | 'withdrawable' | 'burned'

    /**
     * Name of the asset to search
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly name?: string

    /**
     * URL JSON-encoded metadata filters for these assets. Javascript example: encodeURI(JSON.stringify({\&#39;proto\&#39;:[\&#39;1147\&#39;],\&#39;quality\&#39;:[\&#39;Meteorite\&#39;]}))
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly metadata?: string

    /**
     * Set flag to true to fetch an array of sell order details with accepted status associated with the asset
     * @type {boolean}
     * @memberof AssetsApiListAssets
     */
    readonly sellOrders?: boolean

    /**
     * Set flag to true to fetch an array of buy order details  with accepted status associated with the asset
     * @type {boolean}
     * @memberof AssetsApiListAssets
     */
    readonly buyOrders?: boolean

    /**
     * Set flag to include fees associated with the asset
     * @type {boolean}
     * @memberof AssetsApiListAssets
     */
    readonly includeFees?: boolean

    /**
     * Collection contract address
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly collection?: string

    /**
     * Minimum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly updatedMinTimestamp?: string

    /**
     * Maximum timestamp for when these assets were last updated, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly updatedMaxTimestamp?: string

    /**
     * Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly auxiliaryFeePercentages?: string

    /**
     * Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
     * @type {string}
     * @memberof AssetsApiListAssets
     */
    readonly auxiliaryFeeRecipients?: string
}

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * Get details of an asset
     * @summary Get details of an asset
     * @param {AssetsApiGetAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public getAsset(requestParameters: AssetsApiGetAssetRequest, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).getAsset(requestParameters.tokenAddress, requestParameters.tokenId, requestParameters.includeFees, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of assets
     * @summary Get a list of assets
     * @param {AssetsApiListAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public listAssets(requestParameters: AssetsApiListAssetsRequest = {}, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).listAssets(requestParameters.pageSize, requestParameters.cursor, requestParameters.orderBy, requestParameters.direction, requestParameters.user, requestParameters.status, requestParameters.name, requestParameters.metadata, requestParameters.sellOrders, requestParameters.buyOrders, requestParameters.includeFees, requestParameters.collection, requestParameters.updatedMinTimestamp, requestParameters.updatedMaxTimestamp, requestParameters.auxiliaryFeePercentages, requestParameters.auxiliaryFeeRecipients, options).then((request) => request(this.axios, this.basePath));
    }
}
